.ripple-button {
  position: relative;
  overflow: hidden;
 
}

.ripple-button .ripple {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  transform: scale(0);
  animation: ripple-effect 0.4s linear;
}

@keyframes ripple-effect {
  to {
    transform: scale(2);
    opacity: 0;
  }
}
