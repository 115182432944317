
.nav-mbl{
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    padding: 5px;
    position: fixed;
    bottom: 0;
    width:100%;
    border-top: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
  }
  
  
  
  .nav-item {
   display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    text-decoration: none;
    padding: 5px;
    color: black;
  
  }
  

  
  .nav-item:focus span{
    width: 100%;
    margin-left: 8px;
    transition: .2s;
  }
  
