.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.carousel-image {
  max-width: 100%;
  max-height: 300px;
  object-fit: contain;
}

.carousel-button {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.carousel-button.left {
  left: 10px;
}

.carousel-button.right {
  right: 10px;
}