.slideshow-container-mbl {
    position: relative;
    overflow: hidden;


  }
  
  .slides {
    display: flex;
    width: 100%;
    height: 100%;
  }
  
  .slide {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
  }
  
  .slide img {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  
  .slide-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
  }
  
  .slide-caption h2 {
    margin: 0;
    font-size: 2rem;
  }
  
  .slide-caption p {
    margin: 0;
    font-size: 1.5rem;
  }
  
  .prev,
  .next {
    width:35px;
    height:60px;
    cursor:pointer;
    z-index:2;
    color:#fff;
    font-size:17px;
    background:#0167f3;
    border:none;
    padding:2px 5px;
    border-radius:4px;
    -webkit-transition:all .3s ease-out 0s;
    transition:all .3s ease-out 0s;
    position:absolute;
    z-index:99;
    top:50%;
    margin-top:-30px;
    border-radius:0
  }

  .slider-head .prev{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  
  .slider-head .next{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  
  .next {
    right: 0;
  }
  
  .prev:hover,
  .next:hover {
    background-color:#081828;
    color:#fff
  }
  
  .dots-mbl {
    position: relative;
    margin-top: 5px;
    margin-bottom: 5px;
 
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
    white-space: nowrap
 
  }
  
 

 
  .dot-mbl{
    display: inline-block;
    width: 70px;
    height: 70px;
    margin: 0 8px;
  }

  .dot-mbl img{
    width:100%;
    height:100%;
   
  
  }
  .dots-mbl div.active{
    border: solid 2px #0167f3;
  }