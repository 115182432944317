
body{
  background: #f5f5f5;
font-family: arial;
}
.area-chat{
  position: fixed;
  right: 10px;
  bottom: 0;
  z-index: 1000;
}


.area-chat .chatBox{
position: absolute;
z-index: 1000;
}
#chatbox-area{
  
}
.area-msg img{
position: relative;
display: table;
margin: auto;
width: 250px;
margin-bottom: 15px;
}
.area-chat .evmsg{
  position: relative;
  bottom: 15px;;
  background: #282828;
  color: #fff;
  border:0;
  padding: 10px;
  cursor: pointer;
}

.area-chat .evmsg svg{
  position: relative;
  margin-left: 5px;
  font-size: 20px;
}

.card-header {
-webkit-box-align: stretch;
    -ms-flex-align: stretch;
        align-items: stretch;
box-shadow: 0 1px 2px rgba(10, 10, 10, 0.1);
display: -webkit-box;
display: -ms-flexbox;
display: flex;
}

.card-header-title {
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
color: #363636;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-flex: 1;
    -ms-flex-positive: 1;
        flex-grow: 1;
font-weight: 700;
padding: 0.75rem;
}

.card-header-icon {
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
cursor: pointer;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: center;
    -ms-flex-pack: center;
        justify-content: center;
padding: 0.75rem;
}

.card-image {
display: block;
position: relative;
}

.card-content {
padding: 1.5rem;
}

.chat-footer {
border-top: 1px solid #ccc;

}
.chat-footer button{
    position: absolute;
    right: 0;
    background: #0167f3;
    border-radius: 100px;
      width: 35px;
    height: 35px;
    border: solid 1px #0051C2;
    margin: 10px;
}
.chat-footer button:hover{
    background: #005BDB;
}
.chat-footer button:active{
background: #0051C2;
}
.chat-footer button svg{
    color: #fff;
    font-size: 18px;

position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card-footer-item {
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-ms-flex-preferred-size: 0;
    flex-basis: 0;
-webkit-box-flex: 1;
    -ms-flex-positive: 1;
        flex-grow: 1;
-ms-flex-negative: 0;
    flex-shrink: 0;
-webkit-box-pack: center;
    -ms-flex-pack: center;
        justify-content: center;
padding: 0.75rem;
}

.card-footer-item:not(:last-child) {
border-right: 1px solid #dbdbdb;
}

.card {
background-color: white;
box-shadow:0 0 20px #00000012;
color: #4a4a4a;
border: 0;
max-width: 100%;
position: relative;
}

.card .media:not(:last-child) {
margin-bottom: 0.75rem;
}

.level-item {
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-ms-flex-preferred-size: auto;
    flex-basis: auto;
-webkit-box-flex: 0;
    -ms-flex-positive: 0;
        flex-grow: 0;
-ms-flex-negative: 0;
    flex-shrink: 0;
-webkit-box-pack: center;
    -ms-flex-pack: center;
        justify-content: center;
}

.level-item .title,
.level-item .subtitle {
margin-bottom: 0;
}

@media screen and (max-width: 768px) {
.level-item:not(:last-child) {
  margin-bottom: 0.75rem;
}
}

.level-left,
.level-right {
-ms-flex-preferred-size: auto;
    flex-basis: auto;
-webkit-box-flex: 0;
    -ms-flex-positive: 0;
        flex-grow: 0;
-ms-flex-negative: 0;
    flex-shrink: 0;
}

.level-left .level-item:not(:last-child),
.level-right .level-item:not(:last-child) {
margin-right: 0.75rem;
}

.level-left .level-item.is-flexible,
.level-right .level-item.is-flexible {
-webkit-box-flex: 1;
    -ms-flex-positive: 1;
        flex-grow: 1;
}

.level-left {
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
-webkit-box-pack: start;
    -ms-flex-pack: start;
        justify-content: flex-start;
}

@media screen and (max-width: 768px) {
.level-left + .level-right {
  margin-top: 1.5rem;
}
}

@media screen and (min-width: 769px), print {
.level-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
}

.level-right {
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
-webkit-box-pack: end;
    -ms-flex-pack: end;
        justify-content: flex-end;
}

@media screen and (min-width: 769px), print {
.level-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
}

.level {
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
-webkit-box-pack: justify;
    -ms-flex-pack: justify;
        justify-content: space-between;
}

.level:not(:last-child) {
margin-bottom: 1.5rem;
}

.level code {
border-radius: 3px;
}

.level img {
display: inline-block;
vertical-align: top;
}

.allUsersList{
width: 300px;
margin: 20px;
}
.allUsersList .card-header{
background:#0167f3;
color: #FFF;
padding: 10px;
}
.allUsersList .image img{
border-radius: 16px;
}
.usersChatList{
position: absolute;
width: 250px;
bottom: 0;
margin-bottom: 0;
right: 360px;
}
.show{
display: block;
}
.thumb-user-list{
display: none;
}
.thumb-user-list .image img{
border-radius: 30px;
}
.usersChatList .card-header{
background:#0167f3;
font-size: 13px;
}
.chatBox{
position: absolute;
bottom: 0;
right: 0;
width: 320px;
margin: 40px;
margin-bottom: 0;
font-size: 13px;
}
.chat-content{
overflow: auto;
height: 300px;
}
.chatBox .card{
border-radius: 4px;
}
.chatBox .card-header{
background:#0167f3;
 border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.header-title{
height: 50px;
}
.card-header-title .is-online{
font-size: 10px;
color: #32e4cd;
margin-right: 6px;
}
.card-header-title .is-offline{
font-size: 10px;
color: #e43275;
margin-right: 6px;
}
.card-header-title .is-espera{
font-size: 10px;
color: #fff;
margin-right: 6px;
}
.card-header .card-header-title{
color: #FFF;
}
.chat-content small{
margin: 0;
}
.chat-content p{
background: #ecf1f8;
padding: 10px;
border-radius: 8px;
margin-bottom: 0;
}
.my-content .media-content{
width: 80%;
}
.my-content .message{
float: right;
background: #683db8;
color: #FFF;
text-align: right;
padding: 10px;
margin-bottom: 4px;
font-size: 13px;
}
.my-content .chat-content small{
float: right;
}
.my-content small{
display: block;
float: right;
width: 100%;
text-align: right;
}
.chat-textarea{
font-size: 17px;
padding: 8px;
padding-right: 50px;
height: 80px;
width: 100%;
 
border: none;
overflow: auto;
outline: none;
font-family: arial;
-webkit-box-shadow: none;
-moz-box-shadow: none;
box-shadow: none;
resize: none;
box-sizing: border-box;
}
.chat-message-group{

}
.chat-message-group .chat-thumb{
float: left;
width: 20%;
}
.chat-message-group .chat-messages{
float: left;
width: 80%;
margin-bottom: 20px;
}
.chat-message-group .message{
float: left;
padding: 10px;
background: #f1f0f0;
font-size: 15px;
border-radius: 5px;
margin-bottom: 3px;
}
.chat-messages .from{
float: left;
display: block;
width: 100%;
text-align: left;
font-size: 11px;
}
.chat-thumb img{
border-radius: 40px;
}
.writer-user .chat-messages{
float: right;
width: 100%;
}
.writer-user .chat-messages .message{

float: right;
background: #0167f3;
color: #FFF;
}
.writer-user .chat-messages .message .check-msg{
position: relative;
top: 3px;
text-align: right;
}
.writer-user .chat-messages .from{
float: left;
display: block;
width: 100%;
text-align: right;
}
.chat-message-group .typing{
float: left;
}
.chatBox .card-header-icon svg{
color: #FFF;
font-size: 13px;
}
/* CSSS */
.outside-box{
height: 100px;
background: #F8C;
width: 200px;
margin: 20px;
overflow: auto;
}
.outside-box .content-insider{
height: 300px;
background: #C9C;
}
/* CSS Spinner */
.spinner {
margin: 0 30px;
width: 70px;
text-align: center;
}

.spinner > div {
width: 4px;
height: 4px;
background-color: #888;

border-radius: 100%;
display: inline-block;
-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
-webkit-animation-delay: -0.32s;
animation-delay: -0.32s;
}

.spinner .bounce2 {
-webkit-animation-delay: -0.16s;
animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
0%, 80%, 100% { -webkit-transform: scale(0) }
40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
0%, 80%, 100% { 
  -webkit-transform: scale(0);
  transform: scale(0);
} 40% { 
  -webkit-transform: scale(1.0);
  transform: scale(1.0);
}
}
/* EmojiBox */
.emojiBox{
width: 150px;
margin: 30px;
}
.emojiBox .box{
height: 100px;
padding: 4px;
}
/* */
.card-header-title img{
border-radius: 40px;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
  left: 50%;
-webkit-transform: translateX(-50%);
transform: translateX(-50%)
}
.lds-ellipsis div {
  position: absolute;

  top: 45px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #282828;
  
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 5px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 5px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 22px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 46px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}


.chat-footer .opcs{
  padding: 7px;
  height: 35px;
border-top: solid 1px #ccc;
  z-index: 100;
}

.chat-footer .opcs svg{
  cursor: pointer;
  font-size: 19px;
  color: #282828;
    margin-left: 5px;
}
.chat-footer .opcs i:last-child{
  position: relative;
  top: -2px;

}


.load_chat{
  position: absolute;
 width: 100%;
  height: 100%;

  background: #fff;
 margin: -25px;
  z-index: 1000;
}

.load_chat div{
   margin: 0;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}