.player {
    position: relative;
    height: 100%;
    width: 100%;
  }
  
  .player-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) -22.28%,
      rgba(0, 0, 0, 0.2) 100%
    );
    z-index: 1;
    transition: 0.3s opacity;
  }
  

  
  .information-container {
    width: 100%;
    height: 100%;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .player-container {
       position: absolute;
    bottom: 0;
    width: 100%;
   height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .player-progress-bar {
    width: 100%;
  }
  
  .video-progress {
    display: flex;
    width: 100%;
    height: 10px;
    transition: 0.3s;
  background: #919191;
    cursor: pointer;
  }
  
  .video-progress-filled {
    width: 0;
    background-color: #0167f3;

  }
  
  .player-controls {
    width: 100%;
    height: 100%;
    display: flex;
 	background: #3d3d3d; /* IE8- */
	background: #3d3d3d;
  }
  
  .player-buttons {
    display: flex;
    align-items: center;
  }

.player-buttons .volume-progress{
  position: relative;
  margin-top: -2px;
 left: 10px;
}
  
  .volume-progress {
    display: flex;
    width: 100px;
    height: 6px;
    transition: 0.3s;
    background-color: #7c7c7c;
    cursor: pointer;
  }
  
  .volume-progress-filled {
    width: 0;
    background-color: #0167f3;
  }
  
  .time-container {
    display: flex;
    align-items: center;
    margin: 0 20px;
    color: #fff;
  }
  
.time-container p{
  margin-left: 10px;
}
  
  .expand-container {
    display: flex;
    align-items: center;
  }

.player-buttons .button {
  position: relative;
  left: 7px;
  width: 32px;
  height: 32px;
  border: none;
  background-color: transparent;
  background-repeat: none;
  background-position: center;
  cursor: pointer;
 
  outline: none;
}

.player-buttons .button  svg{
  font-size: 18px;
  color: #fff;
}
  