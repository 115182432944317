.pmenu  {
    background-color: #282828;
    width: 100%;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pmenu  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
}

.pmenu  li {
    display: inline-block;
    margin: 0 10px;
}

.pmenu  a {
    display: block;
    padding: 15px;
    color: #fff;
    text-decoration: none;
    font-size: 14px;
}

.pmenu a:hover{
    color:#0167f3;
    text-decoration:none;
    transition:all .4s ease;
    -webkit-transition:all .4s ease;
    -moz-transition:all .4s ease
}