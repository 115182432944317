body{
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  font-family: Arial, Helvetica, sans-serif;
}
.menu-container {
  position: relative;
}

.toggle-button {
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
}

.menu-opc {
  position: fixed;
  bottom: 0;
 
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
 
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-sizing: border-box;
  transform: translateY(100%);
  transition: transform 0.25s ease;
}
.menu-opc ul{
  text-align: center;
}


.camada-menu-mbl{
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
}
.menu-opc.open {
  transform: translateY(0);
}

.menu-opc ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-opc li {
  margin-bottom: 10px;
}
.item-details-mbl{
  background-color: #fff;
}

.ListAddr{

}
.share-item:hover .share-menu{
  display:block;
  opacity: 1;
}

.ctX ul{
  
}

.ctX ul li{

 font-size: 16px;
  cursor: pointer;
  color: #9e9d9d;
  padding: 3px;
  
}
.ctX ul li svg{
  position: relative;
 margin-right: 10px;
 left: 5px;
}
.ctX ul li span{
  position: absolute;
  left: 35px;
}
.ctX ul li:hover{
  background: #454545;
  border-radius: 5px;
  color: #fff;
}

.share-menu{
  position: absolute;
  opacity: 0;
  display: none;
  top: 0;
  border-radius: 5px;
  background: #282828;
  width: 200px;
  right: -190px;
  padding: 5px;
}

.ListAddr .edrc{
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  border: solid 1px;
  padding: 10px 10px;
  border-radius: 5px;

}
.ListAddr .edrc{
  position: relative;
  text-align: center;
  margin-top: 15px;
}
.o-ipt{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.o-ipt div:last-child{
  margin-left: 10px;
   font-weight: 600;
  color: #081828;
  text-align: center;
}
.ListAddr .edrc span{
  font-weight: 600;
  color: #081828;
}
.ListAddr .edrc  .btns-edrc svg:last-of-type{
margin-left: 10px;
}
.ListAddr .edrc  .btns-edrc svg{
font-size: 16px;

}
.scroller__inner {
  padding-bottom: 20px;
  margin-bottom: -20px;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
  white-space: nowrap;
}

.item-details-mbl .product-info{
 
}
.item-details-mbl .product-info .title{

  font-size: 23px;
  text-align: left;
  font-weight: 500;
}


.product-details-info-mbl{
  margin-top: 5px;
  padding: 1px;
}

.product-details-info-mbl h4{
  font-size: 20px;
}

.checkout-steps{
  position: relative;
  margin-top: 15px;
  margin-bottom: -5px;
 border-radius:4px 4px 0 0;

  padding: 10px;
}

.multisteps-mbl li .holder-icon svg{
  color: #fff;
  font-size: 16px;
}

.multisteps-mbl li{
  position: relative;
  float: left;


  width: 33.33333333%;
}



.multisteps-mbl li .holder-icon{
  position: relative;
  display: flex;
  margin: auto;
  background: #ccc;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  z-index: 2;

}

.multisteps-mbl li .holder-icon.active{
  background: #0167f3;
}

.info-usr .nvEdrc{
  font-weight: 600;
  color: #0167f3;
  
}
.multisteps-mbl li .enable{
  position: absolute;
  top: 50%;
  border-bottom: solid 2px #0167f3;
  width: 100%;
  z-index: 1;
}

.multisteps-mbl li .disable{
  position: absolute;
  top: 50%;
  border-bottom: solid 2px #ccc;
  width: 100%;
  z-index: 1;

  
}
.steps{
  position: relative;
  margin-top: 55px;
  
}

.steps .resumo{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e3e8ed;
  padding: 10px;
  border-radius: 5px;
}
.steps .resumo .detalhe div{
  font-weight: 600;
  color: #081828;
}
.steps .resumo .valor div{
color: #0167f3;
font-weight: 600;
font-size: 18px;
}

.product{
  
}
.info-product{
  background-color: #fff;
  border-radius: 5px;
  border:1px solid #e6e6e6;
 
}
.info-product .head{
  display: flex;
  border-bottom: solid 1px #e6e6e6;
  padding: 9px;
  justify-content: space-between;
  align-items: center;
}
.info-product .head span{
  font-weight: 600;
  color: #081828;
}
.info-product .head svg{
  font-weight: 700;
  color: #081828;
}
.info-product .body{
  padding: 10px;
}

.info-product .item{
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #e6e6e6;
  margin-bottom: 10px;
}

.info-product .item img{
  width: 80px;
  height: 80px;
}
.info-product .body .detalhes{
  margin-left: 5px;
}

.info-product .body .detalhes .valor{
font-weight: 600;
color: #0167f3;
}
.area-ipt .error{
  color: #e50f38;

  font-size: 12px;
  line-height: 1.3;
  margin: 7px 0 0;
  text-align: left;
}
.info-product .item .titulo{

  font-weight: 600;
  font-size: 13.5px;
}
.info-product .item .vrc label{
  
  color: #081828;
}
.info-product .item .vrc span{
  margin-left: 3px;
  font-weight: 600;
color: #0167f3;
}
.info-product .total{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-top: -20px;
 
}
.info-product .total .text{
  font-weight: 600;
  color: #081828;
}

.info-product .total .valor{
color:#0167f3;
font-weight: 600;
}

.info-usr{
  position: relative;
  margin-top: 15px;
  margin-bottom: 150px;
  border:1px solid #e6e6e6;
  border-radius: 5px;
  background-color: #fff;
  padding: 10px;
}
.info-usr .head .titulo{
  font-weight: 600;
  color: #081828;
  font-size: 17px;
}
.info-usr .head span{
  color: #081828;
}

.info-usr .body{
  position: relative;
  margin-top: 10px;
}
.info-usr .body .area-ipt{
  margin-top: 10px;
}
.ctna{
  position: relative;
  margin-top: 10px;
  background: #0167f3;
  border-radius: 5px;
  border: 0;
  padding: 10px;
  width: 100%;
  color: #fff;
  font-weight: 600;
}
.nmr-edrc{
  display: flex;
  justify-content: space-between;
}
.nmr-edrc .ar0{
  width: 40%;
}
.nmr-edrc .ar1{
  width: 55%;
}
.info-usr .body .area-ipt input{
  padding: 10px;
  border:solid 1px #ccc;
  border-radius: 5px;
  width: 100%;
}
.info-usr .body .area-ipt.group-error input{
  border: solid 1px #e50f38;
  background: #feecef;
}


.multisteps-mbl li .enable .info{
  position: absolute;
 left: 50%;
 margin-top: 15px;
  color: #081828;
  font-weight: 600;
    transform: translateX(-50%);
  text-align: center;
 
}

.multisteps-mbl li .disable .info{
  position: absolute;
 left: 50%;
   margin-top: 15px;
   font-weight: 600;
    transform: translateX(-50%);
  text-align: center;
}


@media (max-width: 767px) {
  .area-sld img{
    margin-bottom: 10px;
    width: 100%;
  height: 320px;
   
  }
  .product-details-info{
    position: relative;
 
  }

  .item-details-mbl .product-images .magnifier-image{
   height: 300px;
   width:100%;
 }
 .input-number #iptQtd{
 text-align: center;
 font-weight:700;
 }

 .item-details-mbl .product-info #valor{
  color: #0167f3;
  font-size: 20px;
 }
 .input-number{
  flex-direction: row;
 }
 }

 .product-form{

  display: flex;
  justify-content: space-between;
  align-items: center;
 margin-top: 5px;

}
.ctd{
  position: absolute;
   margin: 8px;
   padding: 5px;
 
   background:#ddd ;
 color: #081828;
   border-radius: 5px;
   text-align: center;
   z-index: 1;
   
 
 }
#iptQtd{
  font-size: 16px;
  color: #081828;
  font-weight: 700;
}
.product-form .qtd-slc{
   display: flex;
  justify-content: space-between;
  align-items: center;
 width: 30%;
  padding: 10px;
  height: 45px;
  border: solid 1px #ccc;
  border-radius: 5px;

}
.product-form .qtd-slc div .fa-minus{
 
  padding: 10px;
    color: #081828;
  }
  
  .product-form .qtd-slc div .fa-plus{
    color: #081828;
  padding: 10px;
  }
.product-form .btns{
  position: relative;
   width: 66%;
    
      box-sizing: border-box;
}
.product-form .btns .btn-cpa{
  
  height: 45px;
}

.area-vari{
  margin-top: 0px;
}
.variacao{
  display: flex;
  margin-top: 5px;
  margin-bottom: 10px;
}
.variacao .item{

 
margin-right: 10px;
  border-radius: 5px;
}
.variacao .item label{
  padding: 8px;
  border-radius: 5px;
  color: #081828;
  text-align: center;
  border: solid 1px #ccc;
}
.variacao .item label.active{
border: solid 1px #0167f3;
}

 .btn-cpa{
  background-color: #0167f3;

  width: 100%;
  color: #fff;
  font-weight: 700;
  border:0;
  border-radius: 5px;
 }

 .input-number{
  position: relative;
}
.input-number #rmv{
  position: absolute;
  left: 0px;
}
.input-number #add{
  position: absolute;
  right: 0;
}

.info-body-mobile{
  margin-bottom: 20px;
}

.itens-mbl{
padding: 10px;
}
.area-crr{
height: 100vh;

 
}

.pagamento-mbl{
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color:#fff;
  bottom: 0px;
  padding: 10px;
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  width: 100%;
  height: 65px;
  z-index: 1000;
  
}
.pagamento-mbl .btns{
  position: absolute;
  right: 0;
}
.pagamento-mbl .btns .ctn{
  border: 0;
  height: 65px;
  background-color: #0167f3;
  color: #fff;
  font-weight: 700;
 padding: 0 10px;
}

.pedido-mbl{
 
  position: relative;
  top: 10px;
  padding: 10px;

}
.pedido-mbl .itens-mbl{
  position: relative;
  margin-bottom: 10px;


}
.pedido-mbl img{

  width: 120px;
  height: 120px;
}
.itens-mbl{

 
  border-radius: 5px;

  
  background-color: #fff;
  border: solid 1px #e6e6e6;
}
.itens-mbl .imagem{
  border: solid 1px;
  width: 100px;
  height: 100px;
}
.itens-mbl .dth{
position: relative;

}

.itens-mbl .dth .titulo{
  color: #081828;
  font-weight: 700;
  font-size: 14px;
}

.itens-mbl .dth .area-vl{
  position: absolute;
  bottom: 0;
  width: 100%;
display: flex;
flex: 1;
flex-direction: row;
justify-content: space-between;
align-items: center;
}

.itens-mbl .dth .area-vl .qtd{
  display: flex;
  min-width: 70px;
  padding: 5px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border: solid 1px #ccc;
  border-radius: 5px;
  height: 30px;
}
.itens-mbl .dth .valor{

right: 0;
  color: #0167f3;
  font-weight: 700;
  font-size: 14px;
}